// animation

function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains("gs_reveal_fromLeft")) {
    x = -200;
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = 200;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  elem.classList.add("isActive");

  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto",
    }
  );
}

//test
function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
  elem.classList.remove("isActive");
}

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    //  hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      onEnterBack: function () {
        animateFrom(elem, -1);
      },
      onLeave: function () {
        hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });


  gsap.utils.toArray(".animation_easy-top").forEach(function (elem) {
    //  hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        $(elem).addClass('--animation_easy-top');
      },
      onEnterBack: function () {
        $(elem).removeClass('--animation_easy-top');
      },
      onLeave: function () {
        // hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });
});

// gs_reveal ipsType_center
// gs_reveal gs_reveal_fromLeft
// gs_reveal gs_reveal_fromRight

//
let allPasswords = document.querySelectorAll("input[type=password]");

let eays;
allPasswords.forEach((element) => {
  let parent = element.parentNode;
  let wrapper = document.createElement("div");
  wrapper.classList.add("input-password-parent");

  parent.replaceChild(wrapper, element);
  wrapper.appendChild(element);

  element.parentNode.style.position = "relative";
  eays = document.createElement("button");
  eays.setAttribute("type", "button");
  eays.classList.add("show-hide");
  eays.innerHTML = `<svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9.36786C3.32558 -2.74001 20.5349 -0.634295 21 8.84143" stroke="#000"/>
                      <circle cx="11.5" cy="7.5" r="2.5" fill="#000"/>
                    </svg>
                <div class="password-line off">
                  <svg width="26" height="14" viewBox="0 0 26 14" fill="#212942" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.93469 13C3.36023 12.019 4.79217 11.0915 6.35934 10.3407C9.49128 8.84034 12.232 7.65174 15.2979 6.02005C16.6476 5.30173 18.4132 4.34126 19.7125 3.53937C20.6154 2.98217 21.5272 2.49591 22.5232 2.11885C22.9563 1.95487 23.3801 1.77018 23.8123 1.60466C23.885 1.57678 23.9788 1.50201 25.0001 1" stroke="#212942" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>`;
  element.parentNode.append(eays);
});

// show-hide password
document.querySelectorAll("input[type=password]")?.forEach((el) => {
  el.parentNode.querySelector(".show-hide")?.addEventListener("click", () => {
    if (el.getAttribute("type") === "password") {
      el.setAttribute("type", "text");
    } else {
      el.setAttribute("type", "password");
    }

    el.parentNode.querySelector(".password-line")?.classList.toggle("show");
    el.parentNode.querySelector(".password-line")?.classList.toggle("off");
  });
});
// end show-hide password

// hide <a href="/password-reset/" title="Password Lost and Found." hidden="">Lost your password?</a> on login page
// document
//   .querySelectorAll('[title="Password Lost and Found."]')
//   .forEach((el) => {
//     el.setAttribute("hidden", "");
//   });




$('.slider_container').slick({
  prevArrow: '<img class="prev_a" src="/wp-content/themes/starter/img/slider_prev.svg" alt="">',
  nextArrow: '<img class="next_a" src="/wp-content/themes/starter/img/slider_next.svg" alt="">'
});

$('.question_block').click(function() {
  $(this).toggleClass('active');
  $(this).find('.answer').slideToggle();
})


let dataTitle = "";
document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelectorAll("form.wppb-user-forms ul li")) {
    // задаємо актрибу "data-title" нашим полям
    document.querySelectorAll("form.wppb-user-forms ul li").forEach((el) => {
      dataTitle != "" ? el.setAttribute("data-title", dataTitle) : null;
      el.classList.contains("wppb-heading")
        ? (dataTitle = el.textContent)
        : null;
    });

    // при наитискані на кастомну кнопку змінювати контент
    document.querySelectorAll(".acc-header button").forEach((el) => {
      el.addEventListener("click", () => {
        dataTitle = el.getAttribute("data-title");

        if (dataTitle == "Delete Account") {
          document.querySelector(".wppb-delete-account").click();
        } else {
          document.querySelectorAll(`li[data-title]`).forEach((e) => {
            e.classList.remove("show");
            e.getAttribute("data-title") == dataTitle
              ? e.classList.add("show")
              : null;
          });

          document.querySelectorAll(".acc-header button").forEach((el) => {
            el.classList.remove("current");
          });
          el.classList.add("current");
        }
      });
    });

    document.querySelector(".acc-header button")?.click();
  }
});




$('select').each(function(){
  var $this = $(this), numberOfOptions = $(this).children('option').length;

  $this.addClass('select-hidden'); 
  $this.wrap('<div class="select"></div>');
  $this.after('<div class="select-styled"></div>');

  var $styledSelect = $this.next('div.select-styled');
  $styledSelect.text($this.children('option').eq(0).text());

  var $list = $('<ul />', {
      'class': 'select-options'
  }).insertAfter($styledSelect);

  for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val()
      }).appendTo($list);
  }

  var $listItems = $list.children('li');

  $styledSelect.click(function(e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function(){
          $(this).removeClass('active').next('ul.select-options').hide();
      });
      $(this).toggleClass('active').next('ul.select-options').toggle();
  });

  $listItems.click(function(e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      //console.log($this.val());
  });

  $(document).click(function() {
      $styledSelect.removeClass('active');
      $list.hide();
  });

});


$(document).ready(function() {
  setTimeout(function() { 
    $('.for_loader').hide();
}, 2000);
  
});

// $('.animation_easy-top').addClass('--animation_easy-top');